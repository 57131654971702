// import 'htmx.org';
import * as htmx from "htmx.org";
// import {Modal} from 'bootstrap'
window.htmx = htmx;

let modalEle = document.getElementById("modal");

if (modalEle) {
    const modal = new bootstrap.Modal(modalEle); // eslint-disable-line no-undef
    const event = new Event("updated.bs.modal");

    htmx.on("htmx:afterSwap", (e) => {
        // Response targeting #dialog => show the modal
        if (e.detail.target.id === "dialog") {
            modal.show();
            // document.dispatchEvent(event); // DISPATCH EVENT FOR UPDATING AUTOSIZE TEXT AREAS
        }
    });

    htmx.on("htmx:afterSettle", (e) => {
        // Response targeting #dialog => show the modal
        if (e.detail.target.id === "dialog") {
            document.dispatchEvent(event); // DISPATCH EVENT FOR UPDATING AUTOSIZE TEXT AREAS
        }
    });

    htmx.on("htmx:beforeSwap", (e) => {
        // Empty response targeting #dialog => hide the modal
        if (e.detail.target.id === "dialog" && !e.detail.xhr.response) {
            modal.hide();
            e.detail.shouldSwap = false;
        }
    });

    htmx.on("hidden.bs.modal", () => {
        document.getElementById("dialog").innerHTML = "";
    });
}